<template>
  <div class="container">
    <h2>Dashboard</h2>
    <br/>
    <div class="row">
      <div class="col">
        <dashboardBox url="/porte" link-text="Visualizza" bg-color="bg-info" title="Porte esistenti" value="4" icon="fa fa-door-open" />
      </div>
      <div class="col">
        <dashboardBox url="/porte" link-text="Visualizza" bg-color="bg-success" title="Porte connesse" value="2" icon="fa fa-network-wired" />
      </div>
      <div v-if="$store.getters.isAdmin" class="col">
        <dashboardBox url="/utenti" link-text="Visualizza" bg-color="bg-warning" title="Totale clienti" value="4" icon="fa fa-users" />
      </div>
      <div class="col">
        <dashboardBox bg-color="bg-danger" url="/porte" link-text="Visualizza" title="Porte offline" value="0" icon="fa fa-door-closed" />
      </div>
    </div>
  </div>
</template>

<script>
import SettingsService from '@/services/SettingsService';
import dashboardBox from '@/components/dashboardBox.vue';

export default {
  name: 'HomeView',
  components: {
    dashboardBox,
  },
  data: () => ({
    rooms: [],
    newRoomName: '',
    loading: true,
  }),
  mounted() {
    var self = this;

    SettingsService.loadSettings(this.$store.getters.getJWT)
    .then(res => {
      self.$store.dispatch('setAppName', res.sets.nome_app);
      self.$store.dispatch('setAppLogo', res.sets.logo_app);
    }).catch(err => {
      console.log(err);
    });
  },
  methods: {
    closeModal() {
      document.getElementById('close').click();
    },
  }
}
</script>
