<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 v-if="$store.getters.getUserData.role === 'Admin'">Tutte le porte</h1>
                <h1 v-else>Le mie porte</h1>
            </div>
            <div class="col-2">
                <button @click="$router.push('/aggiungi-porta')" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                    Nuova porta
                </button>
            </div>
        </div>
        <br>
        <loadingSpinner v-if="loading" />
        <data-table v-else :headers="headers">
            <template v-if="doors.length > 0" slot="body">
                <tr :key="door.uid" v-for="door in doors">
                    <td>{{ door.uid }}</td>
                    <td>{{  door.nome }}</td>
                    <td v-if="door.porta_collegata">
                        <router-link :to="'/modifica-porta/'+door.porta_collegata">
                            {{ door.porta_collegata }}
                        </router-link>
                    </td>
                    <td v-else>NA</td>
                    <td>{{ door.utente_id }}</td>
                    <td>
                        <button @click="showModal(door.uid)" class="btn btn-danger">
                            <i class="fa fa-trash icon-btn"></i>
                        </button> / 
                        <button @click="gotoEditDoor(door.uid)" class="btn btn-secondary">
                            <i class="fa fa-pencil icon-btn"></i>
                        </button>
                    </td>
                </tr>
            </template>
            <template slot="body" v-else>
                <div>
                    <h3 class="text-center">Nessuna porta trovata</h3>
                </div>
            </template>
        </data-table>
        <b-modal id="myModal" @close-modal="closeModal" header-title="Sei sicuro?">
            <template slot="modal-body">
                <div class="row">
                    <div class="col">
                        <button @click="confirmDelete" class="btn btn-success">
                            <i class="fa fa-check"></i>
                            Si
                        </button>
                    </div>
                    <div class="col">
                        <button @click="closeModal" class="btn btn-danger">
                            <i class="fa fa-xmark"></i>
                            No
                        </button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import dataTable from '@/components/dataTable.vue';
import loadingSpinner from '@/components/loadingSpinner.vue';
import DoorService from '@/services/DoorService';
import bModal from '@/components/bModal.vue';
import $ from 'jquery';

export default {
    name: 'DoorsView',
    components: {
        dataTable,
        loadingSpinner,
        bModal
    },
    data: () => ({
        headers: [
            {text: 'Id'},
            {text: 'Nome'},
            {text: 'Porta collegata'},
            {text: 'Utente'},
            {text: 'Azione'},
        ],
        doors: [],
        loading: true,
        toDelete: '',
    }),
    mounted() {
        this.loadDoors();
    },
    methods: {
        gotoEditDoor(doorId) {
            this.$router.push(`/modifica-porta/${doorId}`);
        },
        loadDoors() {
            var self = this;

            DoorService.getDoors(this.$store.getters.getJWT)
            .then(res => {
                console.log(res);
                self.doors = res.doors.data;
                self.loading = false;
                console.log(self.doors);
            }).catch(err => {
                console.log(err);
            });
        },
        showModal(id) {
            this.toDelete = id;

            $('#myModal').addClass('show');
            $('#myModal').show();
        },
        closeModal() {
            $('#myModal').removeClass('show');
            $('#myModal').hide();
        },
        confirmDelete() {
            var self = this;
            DoorService.deleteDoor(this.$store.getters.getJWT, this.toDelete)
            .then(res => {
                console.log(res);
                self.closeModal();
                self.loading = true;
                self.loadDoors();
            }).catch(err => {
                alert(err);
            });
        }
    },
}
</script>