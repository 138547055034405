<template>
    <div :class="'small-box '+bgColor">
        <div class="inner">
            <h3>{{ value }}</h3>
            <p>{{ title }}</p>
        </div>
        <div class="icon">
            <i :class="icon"></i>
        </div>
        <router-link class="small-box-footer" :to="url">
            {{ linkText }}
            <i class="fa fa-arrow-circle-right"></i>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'dashboardBox',
    props: {
        bgColor: String,
        value: Number,
        title: String,
        icon: String,
        url: String,
        linkText: String,
    }
}
</script>