<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <h3>Diretta porta: {{ name }}</h3>
                <div ref="players" id="players" class="d-flex justify-content-around">
                    
                </div>
                <br>
                <tab-menu>
                    <b-tab icon="fa fa-edit" :active="tab === 0" @click="tab = 0" title="Modifica" />
                    <b-tab icon="fa fa-upload" :active="tab === 2" @click="tab = 2" title="Spot"></b-tab>
                    <b-tab icon="fa fa-calendar" :active="tab === 1" @click="tab = 1" title="Palinsesto" />
                </tab-menu>
                <br>
                <template v-if="tab === 1">
                    <h3>Programmazione -> {{ name }}</h3>
                    <b-calendar
                        @daySelected="setDay($event)" 
                        type="month"
                        :events="events"
                        @reload-events="loadEvents"
                        />
                    <br>
                </template>
                <template v-if="tab === 2">
                    <b-modal id="upModal" header-title="Uploading files">
                        <template slot="modal-body">
                            <p>
                                Progresso upload: {{ uploadProgress }}%
                            </p>
                        </template>
                    </b-modal>
                    <loading-spinner v-if="loading" />
                    <div class="d-flex justify-content-between">
                        <h3>Spots: {{ name }}</h3>
                        <div class="d-flex">
                            <select v-model="mime" class="form-select">
                                <option value="all">Tutti</option>
                                <option value="image/jpeg">JPEG</option>
                                <option value="image/png">PNG</option>
                                <option value="image/gif">GIF</option>
                                <option value="video/mp4">Video MP4</option>
                            </select>
                            <button @click="chooseFiles()" class="btn btn-primary">
                                <i class="fa fa-upload"></i>
                                Carica file/s
                            </button>
                            <input hidden 
                                type="file"
                                @change="uploadSpots()"
                                class="form-control"
                                accept="image/jpeg, image/png, image/gif, video/mp4"
                                id="files">
                        </div>
                    </div>
                    <br>
                    <div v-if="spots.length > 0" class="row">
                        <div style="box-shadow: 0 0 3px 2px #333;" v-for="s in spots" :key="s.id" class="col-8 mx-auto py-2 my-2">
                            <video v-if="s.mime === 'video/mp4'" class="spot" :src="`${apiUrl}/api/download?file=${s.file_url}`" controls></video>
                            <img v-else class="spot" :src="`${apiUrl}/api/download?file=${s.file_url}`" />
                            <div class="row py-4">
                                <div class="col-4">
                                    <p>{{ s.file_name }}</p>
                                </div>
                                <div class="col-2">
                                    <i @click="deleteSpot(s.id)" style="color: red; cursor: pointer;" class="fa fa-trash"></i>
                                </div>
                                <div class="col-2">
                                    <button
                                        class="btn btn-secondary"
                                        @click="copyFileUrl(s.file_url)"
                                    >
                                        <i class="fa fa-copy"></i>
                                        Copia URL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex align-center">
                        <h5>Nessun file caricato</h5>
                    </div>
                </template>
                <template v-if="tab === 0">
                    <h3>Modifica porta -> {{ name }}</h3>
                    <loading-spinner v-if="loading" />
                    <br>
                    <b-alert v-if="error !== ''" type="danger" :title="error" />
                    <br>
                    <form @submit="handleSubmit">
                        <div class="row">
                            <div class="col">
                                <label>Uid porta</label>
                                <input type="text" v-model="uid" class="form-control">
                            </div>
                            <div class="col">
                                <label>Nome porta</label>
                                <input type="text" v-model="name" class="form-control">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label>Utente</label>
                                <select class="form-select" v-model="user">
                                    <option value="">Seleziona</option>
                                    <option v-for="u in users" :key="u.id" :value="u.id">{{ u.name }}</option>
                                </select>
                            </div>
                            <div class="col">
                                <label>Porta collegata</label>
                                <select class="form-select" v-model="linked">
                                    <option value="">Seleziona</option>
                                    <option v-for="d in doors" :key="d.uid" :value="d.uid">{{ d.nome }}</option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label>Time zone</label>
                                <input type="text" v-model="locale" class="form-control">
                            </div>
                            <div class="col">
                                <label>Sottopancia</label>
                                <input type="text" v-model="subText" class="form-control">
                                <span>Oppure</span>
                                <input type="file" id="subText" class="form-control" />
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label>Video inattività: {{ inactivity }}</label>
                                <input type="file" id="inactive" class="form-control">
                            </div>
                            <div class="col">
                                <label>Stanza diretta</label>
                                <input type="text" v-model="roomName" class="form-control">
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <label>URL: </label>
                                <p>
                                    {{ `https://streaming.sliderconnect.com/join?room=${roomName}&name=${name}` }}
                                </p>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col">
                                <loading-button icon="fa fa-edit" color="success" title="Applica modifiche" :loading="editLoading" />
                            </div>
                        </div>
                    </form>
                    <br>
                    <b-alert type="success" title="Porta modificata con successo" v-if="editOk || uploadOk" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import DoorService from '@/services/DoorService';
import UserService from '@/services/UserService';
import bAlert from '@/components/bAlert.vue';
import loadingSpinner from '@/components/loadingSpinner.vue';
import bCalendar from '@/components/bCalendar.vue';
import loadingButton from '@/components/loadingButton.vue';
import tabMenu from '@/components/tabMenu.vue';
import bTab from '@/components/bTab.vue';
import EventService from '@/services/EventService';
// import netDebugPanel from '@/components/netDebugPanel.vue';
import sock from '@/services/SocketService';
import bModal from '@/components/bModal.vue'
import $ from 'jquery'

export default {
    name: 'editDoorView',
    components: {
        bAlert,
        loadingSpinner,
        bCalendar,
        loadingButton,
        tabMenu,
        bTab,
        bModal,
        // netDebugPanel,
    },
    watch: {
        mime(old, newMime) {
            if(newMime !== old) {
                this.loadSpots()
            }
        }
    },
    data: () => ({
        username: '',
        email: '',
        uploadProgress: 0,
        apiUrl: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEV_API_URL : process.env.VUE_APP_API_URL,
        loading: true,
        editLoading: false,
        error: '',
        uid: '',
        name: '',
        user: '',
        linked: '',
        locale: '',
        spots: [],
        inactivity: '',
        doors: [],
        users: [],
        events: [],
        uploadOk: false,
        editOk: false,
        selectedDay: '',
        tab: 0,
        streamLink: '',
        subText: '',
        roomName: '',
        joined: false,
        iceServers: [],
        peers: {},
        mediaElements: {},
        evtStream: null,
        mime: 'all',
    }),
    mounted() {
        this.loadDoorData();
        this.loadDoors();
        this.loadUsers();
        this.loadEvents();
        this.email = this.$store.getters.getUserData.email
        this.username = this.$store.getters.getUserData.name

        this.uid = this.$route.params.id

        sock.connect();

        sock.on('connect', () => {
            console.log('connected to signaling server',sock.id);
        });

        sock.on('iceCandidate', data => {
            console.log(`iceCandiate from: ${data.peerId}`, data);

            this.peers[data.peerId].addIceCandidate(data.iceCandidate).then(() => {
                console.log("ice candidate set successfully");
            })
            .catch(err => {
                console.error("error setting icecandidate",err);
            });
        });

        sock.on('serverInfo', data => {
            this.joined = true;
            console.log("server data",data);
            var peersCount = data.roomPeersCount;

            if(peersCount !== 0) {
                console.log("room is active");
            } else {
                console.warn("no peer in room");
            }
        });

        sock.on("addPeer", conf => {
            console.log("addPeer",conf);
            const peerId = conf.peerId;
            const shouldCreateOffer = conf.shouldCreateOffer;
            const peerData = conf.peers[peerId];
            console.log("peerConfig", peerData);
            if(!("isAdmin" in peerData)) {
                console.log("adding non-admin peer");
                this.addPeer(conf.peerId, conf.iceServers, shouldCreateOffer);
            }

            // if(shouldCreateOffer && conf.peerId !== sock.id) {
            //     console.log("addPeer", shouldCreateOffer);
                
            // }
        });

        sock.on("sessionDescription", data => {
            console.log("sessionDescription",data);
            this.handleSessionDescription(data.sessionDescription, data.peerId);
        });

        sock.on("removePeer", data => {
            console.log("removePeerData", data);
            if(!("isAdmin" in data)) {
                var peerId = data.peerId;
                delete this.peers[peerId];
                this.$refs.players.remove(this.mediaElements[peerId]);
                console.log(peerId, "has left the room");
            }
        });
    },
    beforeDestroy() {
        try {
            console.log("disconnecting and closing connections");
            sock.disconnect();
            for(let id in this.peers) {
                console.log("closing connection with",id);
                this.peers[id].close();
            }
            for(let peerId in this.mediaElements) {
                console.log(peerId);
                this.$refs.players.remove(this.mediaElements[peerId]);
            }
            this.mediaElements = {};
            this.peers = {};
            sock.removeAllListeners();
            if(this.evtStream !== null) this.evtStream.close()
        } catch(e) {
            console.error(e);
        }
    },
    methods: {
        chooseFiles() {
            document.getElementById('files').click();
        },
        deleteSpot(id) {
            var self = this

            DoorService.deleteSpot(this.$store.getters.getJWT, id)
            .then(res => {
                console.log(res)
                self.loadSpots()
            }).catch(err => {
                console.error(err)
                alert(err.message)
            })
        },
        loadSpots() {
            var self = this;
            this.loading = true

            DoorService.getSpots(this.$store.getters.getJWT, this.uid, this.mime)
            .then(res => {
                console.log(res);
                self.spots = res.spots
                self.loading = false
            }).catch(err => {
                alert(err.message);
                console.log(err);
                self.loading = false
            });
        },
        uploadSpots() {
            // var data = new FormData();
            var self = this;
            var fileUpload = document.getElementById("files");
            console.log(`trying to upload files for ${this.uid}...`)

            if(fileUpload.files.length > 0) {
                let data = new FormData()
                data.append("spot", fileUpload.files[0])
                $('#upModal').show()
                $('#upModal').addClass('show')
                DoorService.uploadFiles(this.$store.getters.getJWT, this.uid, data, (progress) => {
                    self.uploadProgress = Math.floor(progress.progress * 100)
                })
                .then(res => {
                    console.log(res)
                    self.uploadOk = true
                    self.loadSpots()
                    $('#upModal').hide()
                    $('#upModal').removeClass('show')
                }).catch(err => {
                    console.error(err)
                    self.error = err
                    alert(err)
                    $('#upModal').hide()
                    $('#upModal').removeClass('show')
                });
            }
        },
        addPeer(peerId, iceServersConfig, shouldCreateOffer) {
            console.log("iceServersConfig",iceServersConfig);
            var newPeer = new RTCPeerConnection({
                iceServers: iceServersConfig,
            });
            newPeer.addTransceiver('video');
            newPeer.addTransceiver('audio');
            this.peers[peerId] = newPeer;
            newPeer.addEventListener('icecandidateerror', (evt) => {
                console.log("iceCandidateError",evt);
            });

            if(shouldCreateOffer === true) {
                newPeer.addEventListener('negotiationneeded', () => {
                    this.createOffer(peerId);
                });
            }

            newPeer.addEventListener('iceconnectionstatechange', () => {
                console.log('iceConnection',newPeer.iceConnectionState);
            });

            this.peers[peerId].addEventListener("connectionstatechange", evt => {
                console.log("Connection", evt.currentTarget.connectionState);
            });

            this.peers[peerId].addEventListener("track", (evt) => {
                console.log("track");
                console.log("track event", evt.streams[0]);
                console.log("Peers connected", Object.keys(this.peers).length);
                if(Object.keys(this.peers).length <= 2) {
                    if(evt.track.kind === "video") {
                        this.addStream(evt.streams[0], peerId);
                    }
                }
            });

            this.peers[peerId].addEventListener('signalingstatechange', evt => {
                console.log("signalingState",evt);
            });

            this.peers[peerId].addEventListener("icecandidate", evt => {
                console.log(evt);
                if(evt.candidate) {
                    for(let peer in this.peers) {
                        console.log(peer);
                        sock.emit("relayICE", {
                            peerId: peer,
                            iceCandidate: evt.candidate,
                        });
                    }
                }
            });
        },
        addStream(stream, peerId) {
            var newVideo = document.createElement('video');
            newVideo.classList.add('video');
            newVideo.srcObject = stream;
            newVideo.autoplay = true;
            newVideo.controls = true;
            // newVideo.muted = false;
            newVideo.play();

            this.mediaElements[peerId] = newVideo;
            this.$refs.players.appendChild(newVideo);
        },
        createOffer(toId) {
            this.peers[toId].getTransceivers().forEach(t => {
                console.log("transceiver: ", t);
                t.direction = 'recvonly';
            });
            this.peers[toId].createOffer().then(offer => {
                console.log("creating offer", offer);
                this.peers[toId].setLocalDescription(offer)
                .then(() => {
                    console.log("offer setLocalDescription done!");
                    sock.emit("relaySDP", {
                        peerId: toId,
                        sessionDescription: offer,
                    });
                });
            });
        },
        handleSessionDescription(sdp, peerId) {
            this.peers[peerId].setRemoteDescription(new RTCSessionDescription(sdp))
            .then(() => {
                console.log("setRemoteDescription done!");
                if(sdp.type == "offer") {
                    this.peers[peerId].createAnswer()
                    .then(answer => {
                        this.peers[peerId].setLocalDescription(answer)
                        .then(() => {
                            console.log("answer setLocalDescription done!");
                            sock.emit("relaySDP", {
                                peerId: peerId,
                                sessionDescription: sdp
                            });
                        });
                    });
                }
            });
        },
        handleSubmit(e) {
            e.preventDefault();
            var id = this.$route.params.id;
            this.editLoading = true;

            var data = {
                uid: this.uid,
                name: this.name,
                userId: this.user,
                linked: this.linked,
                room_name: this.roomName,
                locale: this.locale
            };
            if(this.subText !== '')
                data.subtext = this.subText;
            if(this.streamLink !== '')
                data.stream_link = this.streamLink;

            var self = this;
            DoorService.editDoor(this.$store.getters.getJWT, this.$store.getters.getCSRF, id, data)
            .then(res => {
                console.log(res);
                self.editOk = true;
                self.editLoading = false;
                self.error = '';
            }).catch(err => {
                console.log(err);
                self.error = err.response.data;
            });

            this.uploadInactive()

            if(document.getElementById('subText').files.length > 0) {
                this.uploadSub();
            }
        },
        uploadSub() {
            var file = document.getElementById("subText").files[0];
            var formData = new FormData();

            formData.append("sub",file);

            DoorService.uploadFiles(this.$store.getters.getJWT, this.uid, formData)
            .then((res) => {
                console.log(res);
            }).catch((err) => {
                console.error(err);
                alert(err.message);
            });
        },
        copyFileUrl(file) {
            navigator.clipboard.writeText(file)
            .then(() => {
                console.log('file url copiato con successo')
            }).catch(err => {
                console.error(err)
                alert(err.message)
            })
        },
        loadDoorData() {
            var id = this.$route.params.id;
            var self = this;

            DoorService.getDoorData(this.$store.getters.getJWT, id)
            .then(res => {
                console.log(res);
                self.loading = false;
                const door = res.door;
                self.uid = door.uid;
                self.name = door.nome;
                self.user = door.utente_id;
                self.linked = door.porta_collegata;
                self.spot1 = door.spot_1;
                self.spot2 = door.spot_2;
                self.inactivity = door.inactive_video;
                self.subText = door.sottopancia;
                self.streamLink = door.link_diretta;
                self.roomName = door.stanza_diretta;
                self.locale = door.locale
                console.log('room name:', self.roomName);
                self.loadSpots();
                sock.emit('join', {
                    channel: door.stanza_diretta,
                    peerInfo: {
                        peerName: "Admin",
                        isAdmin: true,
                        peerAudio: false,
                        peerVideo: true,
                        peerScreen: false,
                    }
                });
            }).catch(err => {
                console.log(err);
                self.error = err.message;
                self.loading = false;
            });
        },
        loadEvents() {
            var self = this;
            EventService.getEvents(this.$route.params.id, this.$store.getters.getJWT)
            .then(res => {
                self.events = res.events;
            }).catch(err => {
                alert(err.message);
                console.log(err);
            });
        },
        loadDoors() {
            var self = this;
            DoorService.getDoors(this.$store.getters.getJWT)
            .then(res => {
                self.doors = res.doors.data;
            }).catch(err => {
                self.error = err.message;
            });
        },
        loadUsers() {
            var self = this;
            UserService.getUsers(this.$store.getters.getJWT)
            .then(res => {
                self.users = res.users.data;
            }).catch(err => {
                self.error = err.message;
            });
        },
        setDay(day) {
            this.selectedDay = day;
        },
        uploadInactive() {
            var self = this
            let inactive = document.getElementById('inactive')
            let formData = new FormData()

            if(inactive.files.length > 0) {
                formData.append("inactive", inactive.files[0])

                DoorService.uploadFiles(this.$store.getters.getJWT, this.uid, formData, (prog) => {
                    self.inactivity = Math.floor(prog.progress*100)+'%'
                }).then(res => {
                    console.log(res)
                    self.uploadOk = true
                }).catch(err => {
                    alert(err.message)
                    console.error(err)
                    self.error = err
                })
            }
        }
    },
    // beforeRouteLeave() {
    //     if(sock.connected) {
    //         sock.emit('admin-leave');
    //     }
    // }
};
</script>