export default {
    state: {
        accessJwt: '',
        userData: null,
        csrfToken: ''
    },
    mutations: {
        setJWT: (state, jwt) => {
            state.accessJwt = jwt;
        },
        setUserData: (state, usr) => {
            state.userData = usr;
        },
        setCSRF: (state, csrf) => {
            state.csrfToken = csrf;
        }
    },
    actions: {
        setJWT: (context, jwt) => context.commit('setJWT',jwt),
        setUserData: (context, usr) => context.commit('setUserData', usr),
        setCSRF: (context, csrf) => context.commit('setCSRF', csrf),
    },
    getters: {
        getJWT: (state) => state.accessJwt,
        getUserData: (state) => state.userData,
        getCSRF: (state) => state.csrfToken,
        isAuth: (state) => {
            return state.accessJwt && state.userData;
        },
        isAdmin: (state) => state.userData.role === 'Admin'
    }
};