<template>
  <div v-if="$store.getters.isAuth" class="wrapper sidebar-mini">
    <main-header />
    <side-bar />
    <div class="content-wrapper px-2 py-4">
      <router-view />
    </div>
  </div>
  <div v-else class="container">
    <router-view />
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap';
import 'admin-lte/dist/js/adminlte';

import mainHeader from '@/components/mainHeader.vue';
import sideBar from '@/components/sideBar.vue';
import axios from 'axios';
// import AuthService from './services/AuthService';
import Swal from 'sweetalert2';

export default {
  name: 'App',
  components: {
    mainHeader,
    sideBar,
  },
  mounted() {
    var self = this;
    axios.interceptors.response.use(
      resp => resp,
      err => {
      if(err.response.status == 403 || err.response.status == 401) {
        localStorage.removeItem("auth");
        this.$store.dispatch("setJWT","");
        this.$store.dispatch("setUserData",null);
        Swal.fire({
          title: 'Errore!',
          text: 'La tua sessione è scaduta, rieffettua il login',
          icon: 'error',
          confirmButtonText: 'Vai al login',
          confirmButtonColor: 'red'
        }).then(() => self.$router.push("/login"));
      }
      return err;
    })
  },
};
</script>

<style>
@import url('./style.scss');
</style>
